import { makeStyles } from '@mui/styles';
import { SvgIcon } from '@mui/material';
import { UseStyles } from '../../helper classes/use-styles-helper';

const useStyles =
  makeStyles({
    root: {
      display: 'flex',
      color: 'black',
      width: '50px',
      height: '50px',
    },
    path: {
      color: 'black !important',
    }
  });

// interface for calling the component directly.
interface IProps extends UseStyles<typeof useStyles> {
  path: string;
  color?: string;
}

export default function ExampleComponent(p: IProps) {

  const classes = useStyles();

  return (
    <SvgIcon classes={{ root: classes.root }}>
      <path className={classes.path} d={p.path} />
    </SvgIcon>
  );

}

/*tslint:disable*/
export const iconInfo = "M229.5,382.5h51v-153h-51V382.5z M255,0C114.75,0,0,114.75,0,255s114.75,255,255,255s255-114.75,255-255S395.25,0,255,0zM255,459c-112.2,0-204-91.8-204-204S142.8,51,255,51s204,91.8,204,204S367.2,459,255,459z M229.5,178.5h51v-51h-51V178.5z";