import { makeStyles } from '@mui/styles';

/// @Author : Simon Meijer
/// <Summary>
/// ..
/// </Summary>

const useStyles =
    makeStyles({
        root: {
            width: '100%',
            height: '100px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        title: {
            fontSize: '1.7em',
            margin: 'auto',
            fontFamily: "Bold",
            textShadow: '0.5px 0.5px 0.5px black',
            textAlign: 'center',
        },
        undertitle: {
            fontSize: '0.5em',
            margin: 'auto',
            fontFamily: "Bold",
            textShadow: '0.5px 0.5px 0.5px black',
        }
    });

interface IComponentProperties {
}

export default function Header(p: IComponentProperties) {
    const classy = useStyles();

    return (
        <div className={classy.root}>
            <div className={classy.title}>
                Simon Meijer<br />

                <div className={classy.undertitle}>
                    Creative Developer
                </div>
            </div>
        </div>
    );
}