import { makeStyles } from '@mui/styles';
import SvgRenderer from '../UI/SvgRenderer';
import { iconForward, iconBack } from '../Images/svgicons';
import { UseStyles } from '../../helper classes/use-styles-helper';

/// @Author : Simon Meijer
/// <Summary>
/// ..
/// </Summary>

const useStyles =
    makeStyles({
        root: {
            cursor: 'pointer',
            width: '50px',
            height: '50px',
            borderRadius: '30px',
            background: '#dddddd',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

        },
        icon: {
            color: 'purple !important'
        }
    });

interface IProps extends UseStyles<typeof useStyles> {
    onClick: () => void;
    isNext: boolean;
}

export default function NextArrow(p: IProps) {
    const classy = useStyles();

    return (
        <div className={classy.root} onClick={() => p.onClick()}>
            <SvgRenderer
                classes={{
                    root: classy.root,
                    path: classy.icon
                }}
                path={p.isNext ? iconForward : iconBack}
            />
        </div>
    );
}