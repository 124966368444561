import { makeStyles } from '@mui/styles';
import Chip from '../UI/Chip';

/// @Author : Simon Meijer
/// <Summary>
/// A tile on the homepage with the title of the of the item, an image and some tags
/// </Summary>

const useStyles =
    makeStyles({
        root: {
            position: 'relative', // important for the title 
            backgroundColor: 'grey',
            width: '100%',
            height: '800px',
            cursor: 'pointer'
        },
        title: {
            position: 'absolute',
            fontSize: '5em',
            left: '50px',
            bottom: '60px',
            width: '70%',
            fontFamily: "Bold",
            color: "white",
            textShadow: '0.5px 0.5px 0.5px black',
            marginBottom: '0.2em'
        },
        image: {
            float: 'inline-start',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
        gradient: {
            position: "absolute",
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            backgroundImage: 'linear-gradient(to right, rgba(0,0,0,0.2), rgba(0,0,0,0));',
        },
        chipContainer: {
            position: 'absolute',
            left: '55px',
            bottom: '25px',
            width: '70%',
            overflow: 'hidden',
            height: '32px',
        },
        chipseperator: {
            display: 'inline-block',
            width: '5px'
        },
        // phones
        "@media (min-width: 320px) and (max-width: 480px)": {
            title: {
                fontSize: '2em'
            },
        }
    });

interface IProps {
    onClick: () => void;
    title: string;
    imagePath: string;
    chips: string[];
}

export default function HomePagePortfolioItem(p: IProps) {
    const classy = useStyles();



    return (
        <div onClick={p.onClick} className={classy.root}>
            <img src={p.imagePath} className={classy.image} alt="" />

            <div className={classy.gradient} />

            <div className={classy.title}>
                {p.title}
            </div>

            <div className={classy.chipContainer}>
                {p.chips.map((item) =>
                    <>
                        <Chip title={item} />
                        <div className={classy.chipseperator} />
                    </>
                )}
            </div>
        </div>
    );
}