import { makeStyles } from '@mui/styles';
import MatChip from '@mui/material/Chip';

/// @Author : Simon Meijer
/// <Summary>
/// ..
/// </Summary>

const useStyles =
    makeStyles({
        root: {
            border: 'solid 2px white !important',
            backgroundColor: 'transparent',
            textTransform: 'uppercase'
        },
        label:
        {
            color: 'white !important'
        }
    });

interface IProps {
    title?: string;
}

export default function Chip(p: IProps) {
    const classy = useStyles();

    return (
        <MatChip
            className={classy.root}
            classes={
                {
                    root: classy.root,
                    label: classy.label
                }
            }
            label={p.title} />
    );
}