import Header from './Components/Layout/Header';
import HomePagePortfolioItem from './Components/Layout/HomePagePortfolioItem';
import DetailviewPage from './Components/Layout/DetailviewPage';

import './App.css';
import './Fonts/Montserrat-Bold.ttf';
import './Fonts/Hind-Medium.ttf';

import { createBrowserRouter, RouterProvider, } from "react-router-dom";

import { move, theScore, cargoCaptain, doritos, duckpocalypse, escapeRoomTheGame, polestarTwo } from './AppContents';

function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element:
        <>
          <HomePagePortfolioItem
            onClick={() => window.location.href = move.pageName }
            title= {move.title}
            imagePath={move.headerImage}
            chips={move.chips}
          />

          <HomePagePortfolioItem
            onClick={() => window.location.href = theScore.pageName}
            title={theScore.title}
            imagePath={theScore.headerImage}
            chips={theScore.chips}
          />

          <HomePagePortfolioItem
            onClick={() => window.location.href = cargoCaptain.pageName}
            title={cargoCaptain.title}
            imagePath={cargoCaptain.headerImage}
            chips={cargoCaptain.chips}
          />

          <HomePagePortfolioItem
            onClick={() => window.location.href = doritos.pageName}
            title={doritos.title}
            imagePath={doritos.headerImage}
            chips={doritos.chips}
          />

          <HomePagePortfolioItem
            onClick={() => window.location.href = duckpocalypse.pageName}
            title={duckpocalypse.title}
            imagePath={duckpocalypse.headerImage}
            chips={duckpocalypse.chips}
          />

          <HomePagePortfolioItem
            onClick={() => window.location.href = polestarTwo.pageName}
            title={polestarTwo.title}
            imagePath={polestarTwo.headerImage}
            chips={polestarTwo.chips}
          />

          <HomePagePortfolioItem
            onClick={() => window.location.href = escapeRoomTheGame.pageName}
            title={escapeRoomTheGame.title}
            imagePath={escapeRoomTheGame.headerImage}
            chips={escapeRoomTheGame.chips}
          />
        </>
    },
    {
      path: move.pageName,
      element:
        <DetailviewPage
          title={move.title}
          content={move.visualItems}
          info={move.about}
          contributions={move.contributions}
        />
    },
    {
      path: theScore.pageName,
      element:
        <DetailviewPage
          title={theScore.title}
          content={theScore.visualItems}
          info={theScore.about}
          contributions={theScore.contributions}
        />
    },
    {
      path: cargoCaptain.pageName,
      element:
        <DetailviewPage
          title={cargoCaptain.title}
          content={cargoCaptain.visualItems}
          info={cargoCaptain.about}
          contributions={cargoCaptain.contributions}
        />
    },
    {
      path: doritos.pageName,
      element:
        <DetailviewPage
          title={doritos.title}
          content={doritos.visualItems}
          info={doritos.about}
          contributions={doritos.contributions}
        />
    },
    {
      path: duckpocalypse.pageName,
      element:
        <DetailviewPage
          title={duckpocalypse.title}
          content={duckpocalypse.visualItems}
          info={duckpocalypse.about}
          contributions={duckpocalypse.contributions}
        />
    },
    {
      path: polestarTwo.pageName,
      element:
        <DetailviewPage
          title={polestarTwo.title}
          content={polestarTwo.visualItems}
          info={polestarTwo.about}
          contributions={polestarTwo.contributions}
        />
    },
    {
      path: escapeRoomTheGame.pageName,
      element:
        <DetailviewPage
          title={escapeRoomTheGame.title}
          content={escapeRoomTheGame.visualItems}
          info={escapeRoomTheGame.about}
          contributions={escapeRoomTheGame.contributions}
        />
    },
  ]);

  return (
    <>
      <Header />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
