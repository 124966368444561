import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ReactPlayer from 'react-player';
import { makeStyles } from '@mui/styles';
import './GalleryStyles.css';
import { useState } from 'react';
import NextArrow from './NextArrow';
import { Content } from '../types';

// https://github.com/leandrowd/react-responsive-carousel

const useStyles =
    makeStyles({
        root: {
            height: '80vh'
        },
        video: {

        },
        img: {
            objectFit: 'cover',
            height: '100%'
        },
        controlArrowRight: {
            position: 'absolute',
            top: '47%',
            zIndex: 10,
            right: '50px',
        },
        controlArrowLeft: {
            position: 'absolute',
            zIndex: 10,
            top: '47%',
            left: '50px',
        }
        ,
        // phones
        "@media (min-width: 320px) and (max-width: 480px)": {
            root: {
                height: '50vh'
            }
        }
    });

interface IProps {
    showArrows: boolean;
    content: Content[];
}

export default function Gallery(p: IProps) {
    const classy = useStyles();

    let elements: JSX.Element[] = [];

    const [videoIndexPlaying, setCurrentVideoPlaying] = useState<number>(0);

    const [curSlide, setCurSlide] = useState<number>(0);

    for (let index = 0; index < p.content.length; index++) {
        const item = p.content[index];

        if (item.type === 'image') {
            elements.push(
                <div key={item.path} className={classy.img}>
                    <img className={classy.img} src={item.path} alt="" />
                </div>
            )
        }

        if (item.type === 'video' || item.type === 'mutedvideo') {
            const videoShouldBePlaying = videoIndexPlaying === index;

            elements.push(
                <VideoSlide playing={videoShouldBePlaying} key={"youtube-" + index} url={item.path} muted={item.type === 'mutedvideo'} />
            )
        }
    }

    const playVideoItemIfAvailable = (index: number) => {
        setCurrentVideoPlaying(index);
    }

    const nextSlide = () => {
        const contentLength = p.content !== undefined && p.content.length > 0 ? p.content.length - 1 : 0;
        const hasNext = curSlide < contentLength;

        if (!hasNext) {
            setCurSlide(0)
        }
        else {
            setCurSlide(curSlide + 1);
        }
    }

    const previousSlide = () => {
        const contentLength = p.content !== undefined && p.content.length > 0 ? p.content.length - 1 : 0;
        const hasPrevious = curSlide > 0;

        if (!hasPrevious) {
            setCurSlide(contentLength)
        }
        else {
            setCurSlide(curSlide - 1);
        }
    }

    const prevNode = <div className={classy.controlArrowLeft}>  <NextArrow isNext={false} onClick={() => previousSlide()} /> </div>

    const nextNode = <div className={classy.controlArrowRight}> <NextArrow isNext={true} onClick={() => nextSlide()} /> </div>

    return (
        <div className={classy.root}>
            <Carousel
                swipeable={true}
                emulateTouch={true}
                className={classy.root}
                onSwipeStart={() => { }}
                onChange={(index, item) => playVideoItemIfAvailable(index)}
                autoPlay={false}
                interval={5000}
                transitionTime={500}
                infiniteLoop
                showThumbs={false}
                showIndicators={true}
                showStatus={false}
                selectedItem={curSlide}
                renderArrowNext={() => p.showArrows ? nextNode : null}
                renderArrowPrev={() => p.showArrows ? prevNode : null}
            >
                {elements}
            </Carousel>
        </div>
    )
}

interface VideoslideProps {
    url: string;
    playing?: boolean;
    muted: boolean;
}

function VideoSlide(p: VideoslideProps) {

    const classy = useStyles();

    return (
        <div onFocus={() => { }} className={classy.img}>
            <ReactPlayer
                controls={true}
                volume={0.5}
                width="100%"
                loop={true}
                height={"100%"}
                url={p.url}              
                muted={p.muted || p.playing === false}                        
            />
        </div>
    );
}