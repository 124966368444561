import { makeStyles } from '@mui/styles';
import Chip from '../UI/Chip';
import Gallery from './Gallery';
import { Content } from '../types';

/// @Author : Simon Meijer
/// <Summary>
/// ..
/// </Summary>

const useStyles =
    makeStyles({
        imageBox: {
            position: 'relative', // important for the title 
            width: '100%',
            height: '80vh'
        },
        title: {
            position: 'absolute',
            fontSize: '5em',
            left: '50px',
            bottom: '80px',
            width: '70%',
            fontFamily: "Bold",

            textShadow: '0.5px 0.5px 0.5px black',
            marginBottom: '0.5em'
        },
        image: {
            float: 'inline-start',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
        gradientOverlay: {
            position: "absolute",
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            backgroundImage: 'linear-gradient(to right, rgba(0,0,0,0.3), rgba(0,0,0,0));',
            pointerEvents: 'none'
        },
        chipContainer: {
            position: 'absolute',
            left: '55px',
            bottom: '45px',
            width: '70%',
            overflow: 'hidden',
            height: '32px'
        },
        chipseperator: {
            display: 'inline-block',
            width: '5px'
        },
        descriptionBox: {
            padding: "50px"
        },
        descriptionBoxTitle: {
            fontSize: '5em',
            paddingBottom: '10px',
            width: '70%',
            fontFamily: "Bold",
            textShadow: '0.5px 0.5px 0.5px black'
        },
        descriptionTextbox: {
            maxWidth: '50em',
            paddingBottom: '50px'
        },
        // phones
        "@media (min-width: 320px) and (max-width: 480px)": {
            title: {
                fontSize: '2em'
            },
            descriptionBoxTitle: {
                fontSize: '2em'
            },
            imageBox: {
                height: '50vh'
            }
        }

    });

interface IProps {
    title: string;
    content: Content[];
    info: string;
    contributions: string[];
}

export default function DetailviewPage(p: IProps) {
    const classy = useStyles();

    const x = window.matchMedia("(min-width: 320px) and (max-width: 480px)");

    return (
        <>
            <div className={classy.imageBox}>
                <div>
                    <Gallery
                        showArrows={!x.matches}
                        content={p.content}
                    />
                    <div className={classy.gradientOverlay} />
                </div>
            </div>

            <div className={classy.descriptionBox}>
                <div className={classy.descriptionBoxTitle}>
                    {p.title}
                </div>

                <div className={classy.descriptionTextbox}>
                    {p.info}
                </div>

                <div className={classy.descriptionBoxTitle}>
                    Contributions
                </div>

                <div className={classy.descriptionTextbox}>
                    {p.contributions.map((item) =>
                        <li>
                            {item}
                        </li>
                    )}
                </div>
            </div>
        </>
    );
}